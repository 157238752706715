/* eslint-disable node/no-unsupported-features/node-builtins */
import {ErrorInfo, createRoot} from 'react-dom/client';
import ApplyView from './views/Apply';

const container = document.getElementById('App') as Element;
const reactRoot = createRoot(container, {
  onRecoverableError: (error: unknown, errorInfo: ErrorInfo) => {
    console.log(`onRecoverableError: ${error} \n ${errorInfo}`);
  },
});

let imageURLParam = new URLSearchParams(window.location.search).get(
  'image_url'
);
let tenantIDParam = new URLSearchParams(window.location.search).get(
  'tenant_id'
);
if (imageURLParam && tenantIDParam) {
  imageURLParam = decodeURIComponent(imageURLParam);
  tenantIDParam = decodeURIComponent(tenantIDParam);
  reactRoot.render(
    <ApplyView imageURL={imageURLParam} tenantID={tenantIDParam} />
  );
} else {
  reactRoot.render(<>URL NOT FOUND!</>);
}
