/* eslint-disable @typescript-eslint/no-unused-vars */
import {Alert, Button, Grid, Snackbar, Stack, TextField} from '@mui/material';
import React, {useState} from 'react';
import InputMask from 'react-input-mask';

import 'react-phone-number-input/style.css';

interface ApplyViewProps {
  imageURL: string;
  tenantID: string;
}

const ApplyView: React.FC<ApplyViewProps> = ({imageURL, tenantID}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const [showButton, setButtonVisibility] = React.useState(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/\D/g, ''); // Remove non-digit characters

    setPhoneNumber(input);
    setPhoneNumberError(false);
  };

  const handleSubmit = () => {
    if (!phoneNumber.match(/^\d{10}$/)) {
      setPhoneNumberError(true);
    } else {
      const formattedPhoneNumber = `+1${phoneNumber}`; // Prepend '1' to the phone number
      setButtonVisibility(false);
      fetch(
        'https://gcy3wpta7l.execute-api.ca-central-1.amazonaws.com/prod/invite/tenant',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phone: formattedPhoneNumber,
            tenant_id: tenantID,
          }), // Use 'phone' as the key for the phone number
        }
      )
        .then(response => response.json())
        .then(data => {
          // Handle response data
          console.log('Response:', data);
          setOpen(true);
          setButtonVisibility(true);
          setPhoneNumber('');
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
          setButtonVisibility(true);
          setPhoneNumber('');
        });
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{minHeight: '90vh'}}
    >
      <Stack spacing={2} sx={{width: '100%'}}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Please check your SMS for the download link!
          </Alert>
        </Snackbar>
      </Stack>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        sx={{maxWidth: '70%', textAlign: 'center'}}
      >
        <img src={imageURL} alt="" style={{width: '70%', height: 'auto'}} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{width: '100%', maxWidth: '70%', marginTop: '10px'}}
      >
        <InputMask
          mask="(999) 999-9999"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        >
          {(inputProps: unknown) => (
            <TextField
              label="Enter Phone Number"
              variant="outlined"
              value={phoneNumber}
              error={phoneNumberError}
              helperText={phoneNumberError ? 'Invalid phone number' : ''}
              sx={{
                width: '100%',
                marginBottom: '10px',
                marginTop: '10px',
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'black',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'black',
                  },
              }}
              {...inputProps}
            />
          )}
        </InputMask>
      </Grid>
      {showButton && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{bgcolor: 'black', color: 'white'}}
          >
            Apply To Company
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ApplyView;
